<template>
    <div class="desktopLayoutClass">
    <v-app style="min-height: 80vh !important;">
     <div
         v-show="show_header"
         class="header"
         style="
           display: flex;
           flex-direction: row;
           padding-top: 10px;
           padding-left: 5px;
            background-color:white;
         "
       >
         <div style="display: flex; flex-direction: row; width: 100%">
           <div style="width: 90%; margin-left: 10px;" align="left">
             <span style="font-weight: 600; font-size: 15pt"
               >{{ $t("Customer.UserProfile.Terms_And_Conditions") }}</span
             >
           </div>
         </div>
       </div>
 
        <div style=" height: 90vh;
         overflow: auto;
         float: left;
         position: relative;">
       <div style="font-weight:bold;margin-left:20px;margin-top:20px;" align="left">
           <p style="font-weight:400;" align="left">{{$t("Terms_And_Conditions.paragraph")}}</p>
       </div>  
       <div style="font-weight:bold;margin-left:20px;margin-top:20px;" align="left">
           <p style="font-weight:400;" align="left"><b>{{$t("Terms_And_Conditions.Notice")}}</b></p>
       </div>
       <div style="font-weight:bold;margin-left:20px;margin-top:20px;" align="left">
         <p><b>{{$t("Terms_And_Conditions.Heading")}}</b></p>
         <!-- <p>TERMS AND CONDITIONS FOR USE OF SERVICE (Terms and Conditions) </p>-->
       </div>
       <!-- <div style="margin-left:20px;margin-top:20px;">
         <p style="font-weight:400;" align="left">{{$t("Terms_And_Conditions.Para_One")}}<b>{{$t("Terms_And_Conditions.Para_One_Bold")}}</b></p>
       </div> -->
       
       <!-- <div style="margin-left:20px;margin-top:20px;" align="left">
           <p><b>DEFINITIONS AND INTERPRETATION:</b><br>
           Please see the end of these Terms and Conditions.</p>
       </div>
       <div style="margin-left:20px;margin-top:20px;" align="left">
         <p><b>The Digital Health Payment Platform</b> is intended for Myanmar national users only within the territory of the Republic of the Union of Myanmar. If you are not a Myanmar national, or are not within the territory of the Republic of the Union of Myanmar, you may not use this service.</p>
       </div>
       <div style="margin-left:20px;margin-top:20px;" align="left">
         <p><b>The Digital Health Payment Platform</b> [a service by which users anywhere in Myanmar can access up to date medical and pharmaceutical information over their mobile phones using the KBZPay app]</p>
       </div>
       <div style="margin-left:20px;margin-top:20px;" align="left">
         <p><b>The Digital Health Payment Platform</b> charges by [Francis please describe how <b>The Digital Health Payment Platform</b> charges]).</p>
       </div> -->
 
       <div>
         <v-expansion-panels focusable>
 
           <v-expansion-panel>
             <v-expansion-panel-header><p>{{$t("Terms_And_Conditions.Expansion_Panel_1.Heading")}}</p></v-expansion-panel-header>
               <v-expansion-panel-content style="margin-top:20px;" align="left">
                 
                 <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_1.User.Para_1.Content_1")}}
                                         <b>{{$t("Terms_And_Conditions.Expansion_Panel_1.User.Para_1.Content_2")}}</b>
                                           {{$t("Terms_And_Conditions.Expansion_Panel_1.User.Para_1.Content_3")}} </p>  
 
                 <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_1.Account.Para_1.Content_1")}}
                                         <b>{{$t("Terms_And_Conditions.Expansion_Panel_1.Account.Para_1.Content_2")}}</b>
                                           {{$t("Terms_And_Conditions.Expansion_Panel_1.Account.Para_1.Content_3")}} </p>                                        
 
 
                 <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_1.Advice.Para_1.Content_1")}}
                                         <b>{{$t("Terms_And_Conditions.Expansion_Panel_1.Advice.Para_1.Content_2")}}</b>
                                           {{$t("Terms_And_Conditions.Expansion_Panel_1.Advice.Para_1.Content_3")}} </p>  
 
                 <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_1.Content.Para_1.Content_1")}}
                                         <b>{{$t("Terms_And_Conditions.Expansion_Panel_1.Content.Para_1.Content_2")}}</b>
                                           {{$t("Terms_And_Conditions.Expansion_Panel_1.Content.Para_1.Content_3")}} </p> 
 
                 <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_1.Health History.Para_1.Content_1")}}
                                         <b>{{$t("Terms_And_Conditions.Expansion_Panel_1.Health History.Para_1.Content_2")}}</b>
                                           {{$t("Terms_And_Conditions.Expansion_Panel_1.Health History.Para_1.Content_3")}}
                                          <b>{{$t("Terms_And_Conditions.Expansion_Panel_1.Health History.Para_1.Content_4")}}</b>
                                           {{$t("Terms_And_Conditions.Expansion_Panel_1.Health History.Para_1.Content_5")}}  </p>   
                                                                                              
                  <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_1.Healthcare Providers.Para_1.Content_1")}}
                                         <b>{{$t("Terms_And_Conditions.Expansion_Panel_1.Healthcare Providers.Para_1.Content_2")}}</b>
                                           {{$t("Terms_And_Conditions.Expansion_Panel_1.Healthcare Providers.Para_1.Content_3")}}
                                          <b>{{$t("Terms_And_Conditions.Expansion_Panel_1.Healthcare Providers.Para_1.Content_4")}}</b>
                                           {{$t("Terms_And_Conditions.Expansion_Panel_1.Healthcare Providers.Para_1.Content_5")}}  </p>  
 
                 <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_1.Order.Para_1.Content_1")}}
                                         <b>{{$t("Terms_And_Conditions.Expansion_Panel_1.Order.Para_1.Content_2")}}</b>
                                           {{$t("Terms_And_Conditions.Expansion_Panel_1.Order.Para_1.Content_3")}} </p>                            
 
                 <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_1.Service.Para_1.Content_1")}}
                                         <b>{{$t("Terms_And_Conditions.Expansion_Panel_1.Service.Para_1.Content_2")}}</b>
                                           {{$t("Terms_And_Conditions.Expansion_Panel_1.Service.Para_1.Content_3")}}
                                          <b>{{$t("Terms_And_Conditions.Expansion_Panel_1.Service.Para_1.Content_4")}}</b>
                                           {{$t("Terms_And_Conditions.Expansion_Panel_1.Service.Para_1.Content_5")}}  </p>  
 
                 <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_1.User Content.Para_1.Content_1")}}
                                         <b>{{$t("Terms_And_Conditions.Expansion_Panel_1.User Content.Para_1.Content_2")}}</b>
                                           {{$t("Terms_And_Conditions.Expansion_Panel_1.User Content.Para_1.Content_3")}} </p> 
 
                 <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_1.KBZ Bank.Para_1.Content_1")}}
                                         <b>{{$t("Terms_And_Conditions.Expansion_Panel_1.KBZ Bank.Para_1.Content_2")}}</b>
                                           {{$t("Terms_And_Conditions.Expansion_Panel_1.KBZ Bank.Para_1.Content_3")}} </p>                                                     
 
                  <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_1.KBZPay.Para_1.Content_1")}}
                                         <b>{{$t("Terms_And_Conditions.Expansion_Panel_1.KBZPay.Para_1.Content_2")}}</b>
                                           {{$t("Terms_And_Conditions.Expansion_Panel_1.KBZPay.Para_1.Content_3")}} </p>  
 
                   <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_1.Website.Para_1.Content_1")}}
                                         <b>{{$t("Terms_And_Conditions.Expansion_Panel_1.Website.Para_1.Content_2")}}</b>
                                           {{$t("Terms_And_Conditions.Expansion_Panel_1.Website.Para_1.Content_3")}}
                                         <a>{{$t("Terms_And_Conditions.Expansion_Panel_1.Website.Para_1.Content_4")}}</a>
                                           {{$t("Terms_And_Conditions.Expansion_Panel_1.Website.Para_1.Content_5")}}  </p>                         
 
                   <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_1.Terms.Para_1.Content_1")}}
                                         <b>{{$t("Terms_And_Conditions.Expansion_Panel_1.Terms.Para_1.Content_2")}}</b>
                                           {{$t("Terms_And_Conditions.Expansion_Panel_1.Terms.Para_1.Content_3")}} </p>                           
 
                 <!-- <p style="margin-top:12px"><b>{{$t("Terms_And_Conditions.Expansion_Panel_1.Website.Heading")}}</b></p>
                 <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_1.Website.Content.Para_1")}}
                                             <a href="https://www.kbzbank.com/en/" >{{$t("Terms_And_Conditions.Expansion_Panel_1.Website.Content.Para_3")}}</a>
                                             <a href="https://www.kbzpay.com/en/">{{$t("Terms_And_Conditions.Expansion_Panel_1.Website.Content.Para_4")}}</a>
                                             {{$t("Terms_And_Conditions.Expansion_Panel_1.Website.Content.Para_2")}}</p> -->
                 <!--<b>The Digital Health Payment Platform</b>  employees) and not to make independent recordings of consultations, capture images, or take screen shots or record voice or data or similar; all necessary details of your consultations may be stored for your benefit in your own health history (
                 <b>Health History</b>); we may record your mobile calls with
                 <b>The Digital Health Payment Platform;</b> any information you provide
                 <b>The Digital Health Payment Platform</b>  and its Healthcare Providers will be accurate and you will not omit anything of relevance or importance, which for the avoidance of doubt includes, current and past, medications and conditions; you will maintain and ensure that your Health History is current and correct at the time of any appointment or consultation and add any relevant advice, opinions, tests, prescriptions or imaging you have received from any source; you will comply with all instructions and advice given to you by Healthcare Providers for your care; you will comply with the manufacturers’ instructions and guidance as to the use of any medicine and the instructions of any Pharmacist during and following a consultation; you will promptly report any side effects of any prescription to an appropriate Healthcare Provider; you consent to
                 <b>The Digital Health Payment Platform</b>  processing your personal information including your Health Information and details of all your past medical consultations, collectively your <b>Health History;</b> you agree, <b>The Digital Health Payment</b>
                  may use Anonymized Data in the improvement of its Services. In this context, Anonymised Data refers to data from which you cannot be individually identified; if you have any concerns or are in any doubt regarding any information or Advice you have received or failed to receive via
                 <b>The Digital Health Payment Platform</b>  Services, or regarding your health, wellbeing or any conditions, you will immediately seek a further medical opinion from a registered doctor or suitable healthcare provider and/or shall utilise the emergency services as necessary. -->
               </v-expansion-panel-content>
           </v-expansion-panel>
 
         <v-expansion-panel>
          <v-expansion-panel-header>{{$t("Terms_And_Conditions.Expansion_Panel_2.Heading")}}</v-expansion-panel-header>
          <v-expansion-panel-content style="margin-top:20px;" align="left">
            <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_2.Para_1")}}</p>
          </v-expansion-panel-content>
        </v-expansion-panel>
 
        <v-expansion-panel>
          <v-expansion-panel-header>{{$t("Terms_And_Conditions.Expansion_Panel_3.Heading")}}</v-expansion-panel-header>
          <v-expansion-panel-content style="margin-top:20px;" align="left">
            <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_3.Para_1.Content_1")}}</p>
                                    
           <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_3.Para_2.Content_1")}}</p>
 
           <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_3.Para_3.Content_1")}}</p>
 
           <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_3.Para_4.Content_1")}}</p>
 
           <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_3.Para_5.Content_1")}}</p>
 
           <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_3.Para_6.Content_1")}}</p>
           <p style="margin-top:12px;margin-left:10px;">{{$t("Terms_And_Conditions.Expansion_Panel_3.Para_6.Content_2")}}</p>
           <p style="margin-top:12px;margin-left:10px;">{{$t("Terms_And_Conditions.Expansion_Panel_3.Para_6.Content_3")}}</p> 
 
           <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_3.Para_7.Content_1")}}</p>   
 
           <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_3.Para_8.Content_1")}}</p>  
 
           <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_3.Para_9.Content_1")}}</p>   
 
             <!-- <p><b> ACCESS TO THE SERVICE AND THE INFORMATION CONTAINED THEREIN IS PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT ANY WARRANTY OF ANY KIND, EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, The Digital Health Payment Platform DISCLAIMS ALL WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. WITHOUT LIMITING THE FOREGOING, The Digital Health Payment Platform DOES NOT WARRANT THAT ACCESS TO THE SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE, OR THAT DEFECTS, IF ANY, WILL BE CORRECTED; NOR DOES The Digital Health Payment Platform MAKE ANY REPRESENTATIONS ABOUT THE ACCURACY, RELIABILITY, CURRENCY, QUALITY, COMPLETENESS, USEFULNESS, PERFORMANCE, SECURITY, LEGALITY OR SUITABILITY OF THE SERVICE OR ANY OF THE INFORMATION CONTAINED THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE OF THE SERVICE AND YOUR RELIANCE UPON ANY ADVICE IS AT YOUR SOLE RISK.</b></p>
             <p><b> YOU SHALL BE SOLELY AND FULLY RESPONSIBLE FOR ANY DAMAGE TO THE SERVICE OR ANY COMPUTER SYSTEM, ANY LOSS OF DATA, OR ANY IMPROPER USE OR DISCLOSURE OF INFORMATION ON THE SERVICE CAUSED BY YOU OR ANY PERSON USING YOUR USERNAME OR PASSWORD. The Digital Health Payment Platform CANNOT AND WILL NOT ACCEPT ANY RESPONSIBILITY FOR THE LOSS OR MISUSE OF YOUR PASSWORD. The Digital Health Payment Platform CANNOT AND DOES NOT ASSUME ANY RESPONSIBILITY FOR ANY LOSS, DAMAGES OR LIABILITIES ARISING FROM THE FAILURE OF ANY TELECOMMUNICATIONS INFRASTRUCTURE, OR THE INTERNET OR FOR YOUR MISUSE OF ANY HEALTH INFORMATION, ADVICE, IDEAS, INFORMATION, INSTRUCTIONS OR GUIDELINES ACCESSED THROUGH THE SERVICE.</b></p>
             <p><b> YOU SHALL BE SOLELY AND FULLY RESPONSIBLE FOR ANY DAMAGE TO THE SERVICE OR ANY COMPUTER SYSTEM, ANY LOSS OF DATA, OR ANY IMPROPER USE OR DISCLOSURE OF INFORMATION ON THE SERVICE CAUSED BY YOU OR ANY PERSON USING YOUR USERNAME OR PASSWORD. The Digital Health Payment Platform CANNOT AND WILL NOT ACCEPT ANY RESPONSIBILITY FOR THE LOSS OR MISUSE OF YOUR PASSWORD. The Digital Health Payment Platform CANNOT AND DOES NOT ASSUME ANY RESPONSIBILITY FOR ANY LOSS, DAMAGES OR LIABILITIES ARISING FROM THE FAILURE OF ANY TELECOMMUNICATIONS INFRASTRUCTURE, OR THE INTERNET OR FOR YOUR MISUSE OF ANY HEALTH INFORMATION, ADVICE, IDEAS, INFORMATION, INSTRUCTIONS OR GUIDELINES ACCESSED THROUGH THE SERVICE.</b></p>
      -->
          </v-expansion-panel-content>
        </v-expansion-panel>
 
        <v-expansion-panel>
          <v-expansion-panel-header>{{$t("Terms_And_Conditions.Expansion_Panel_4.Heading")}}</v-expansion-panel-header>
          <v-expansion-panel-content style="margin-top:20px;" align="left">
            <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_4.Para_1")}}</p>
            <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_4.Para_2")}}</p>
            <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_4.Para_3")}}</p>
            <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_4.Para_4")}}</p>
            <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_4.Para_5")}}</p>
            <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_4.Para_6")}}</p>
            <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_4.Para_7")}}</p>
            <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_4.Para_8")}}</p>
             <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_4.Para_9")}}</p>
             <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_4.Para_10")}}</p>
             <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_4.Para_11")}}</p>
             <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_4.Para_12")}}</p>
             <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_4.Para_13")}}</p>
             <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_4.Para_14")}}</p>
             <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_4.Para_15")}}</p>
              <!-- <p><b> IN THE EVENT OF ANY PROBLEM WITH The Digital Health Payment Platform OR ANY ADVICE PROVIDED THROUGH ITS SERVICES, YOU AGREE THAT YOUR SOLE REMEDY IS TO CEASE USING The Digital Health Payment Platform. UNDER NO CIRCUMSTANCES SHALL The Digital Health Payment Platform BE LIABLE IN ANY WAY FOR YOUR USE OF THE SERVICE OR ANY ADVICE GIVEN BY ANY HEALTHCARE PROVIDERS, INCLUDING, BUT NOT LIMITED TO, ANY ERRORS OR OMISSIONS IN ANY ADVICE, ANY INFRINGEMENT BY ANY CONTENT OF THE INTELLECTUAL PROPERTY RIGHTS OR OTHER RIGHTS OF THIRD PARTIES, OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF FOLLOWING ANY ADVICE OR THE USE OF ANY CONTENT.</b></p>
              <p><b> UNDER NO CIRCUMSTANCES SHALL The Digital Health Payment Platform BE LIABLE FOR ANY PUNITIVE, EXEMPLARY, CONSEQUENTIAL, INCIDENTAL, INDIRECT OR SPECIAL DAMAGES (INCLUDING, WITHOUT LIMITATION, ANY PERSONAL INJURY, LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF PROGRAMS OR OTHER DATA ON YOUR MOBILE OR OTHERWISE) ARISING FROM OR IN CONNECTION WITH YOUR USE OF THE SERVICE, WHETHER UNDER A THEORY OF BREACH OF CONTRACT, NEGLIGENCE, STRICT LIABILITY, MALPRACTICE OR OTHERWISE, EVEN IF WE OR THEY HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</b></p>
              <p><b> TO THE FULLEST EXTENT PERMISSIBLE BY LAW, The Digital Health Payment Platform ACCEPTS NO LIABILITY TO ANY USER FOR ANY LOSS OR DAMAGE, WHETHER FORESEEABLE OR OTHERWISE, IN CONTRACT, TORT (INCLUDING NEGLIGENCE), FOR BREACH OF STATUTORY DUTY, OR OTHERWISE, ARISING OUT OF OR ABOUT THE USE OF (OR INABILITY TO USE) APP OR THE USE OF OR RELIANCE UPON ANY ADVICE GIVEN THROUGH OR CONTENT INCLUDED ON THE DIGITAL HEALTH PAYMENT PLATFORM APP. </b></p> -->
          </v-expansion-panel-content>
        </v-expansion-panel>
 
        <v-expansion-panel>
          <v-expansion-panel-header>{{$t("Terms_And_Conditions.Expansion_Panel_5.Heading")}}</v-expansion-panel-header>
          <v-expansion-panel-content style="margin-top:20px;" align="left">
            <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_5.Para_1")}}</p>
            <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_5.Para_2")}}</p>
            <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_5.Para_3")}}</p>
            <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_5.Para_4")}}</p>
 
             <!-- You will indemnify, defend and hold harmless the <b>Digital Health Payment Platform</b>  from all claims, actions, proceedings, losses, settlements, judgments, liabilities, suits, damages, disputes or demands, including without limitation any proceeding, investigation or claim by a regulatory organization,  including reasonable attorneys fees and all other costs, fees, and expenses (collectively, <b>Claims</b>) against The Digital Health Payment Platform to the fullest extent permitted by law arising out of or in connection with (1) your conduct, provision of Content or use of The Digital Health Payment Platform; (2) your violation of the rights of another person or party; (3) any materials provided or made available by you; and (4) any breach or violation by you of your obligations under these Terms and Conditions. -->
          </v-expansion-panel-content>
        </v-expansion-panel>
 
        <v-expansion-panel>
          <v-expansion-panel-header>{{$t("Terms_And_Conditions.Expansion_Panel_6.Heading")}}</v-expansion-panel-header>
          <v-expansion-panel-content style="margin-top:20px;" align="left">
          <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_6.Para_1")}}</p>
          <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_6.Para_2")}}</p>
 
 
             <!-- <p> 6.1. The App is not suitable for any condition that requires face to face analysis, diagnosis or treatment, or for obtaining any Service urgently for a medical emergency or acute condition.</p>
             <p> 6.2. <b>The Digital Health Payment Platform</b> cannot guarantee continuity of care with the same Healthcare Provider. </p>
             <p> 6.3. <b>The Digital Health Payment Platform</b>  makes no representation, warranty, or guarantee that The Digital Health Payment Platform Services or App will meet your requirements; be of satisfactory quality; be fit for a purpose; not infringe the rights of third parties; be compatible with all software and hardware; or that it will be secure. </p>
             <p> 6.4  <b>The Digital Health Payment Platform</b>  makes reasonable efforts to ensure that the Content on the App is complete, accurate, and up-to-date. <b>The Digital Health Payment Platform</b>  does not make any representations, warranties or guarantees (whether express or implied) that the Content is complete, accurate, or up-to-date.</p> -->
          </v-expansion-panel-content>
        </v-expansion-panel>
 
         <v-expansion-panel>
          <v-expansion-panel-header>{{$t("Terms_And_Conditions.Expansion_Panel_7.Heading")}}</v-expansion-panel-header>
          <v-expansion-panel-content style="margin-top:20px;" align="left">
            <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_7.Para_1")}}</p>
            <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_7.Para_2")}}</p>
            <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_7.Para_3")}}</p>  
               <!-- <p> 7.1. Subject to the below,<b> The Digital Health Payment Platform</b>  grants you a limited license to access and make personal use of the App and not to modify any portion. This license does not include any resale or commercial use of the App; any derivative use of the App; or any use of data mining, robots, or similar data gathering and extraction tools. </p>
              <p> 7.2. The App and all Content, organization, graphics, design, compilation, translation, and other matters related to the App are protected under applicable copyrights, trademarks, and other proprietary rights pursuant to international conventions and Myanmar and other laws. The App and all Content is the property of <b>The Digital Health Payment Platform</b>  and/or third party licensors, and all right, title and interest in and to the Site and Content will remain with <b>The Digital Health Payment Platform</b>  or such third party licensors. You do not acquire ownership rights to the App or any Content other than any rights in the materials that you may have. You will abide by any and all additional copyright notices, information, or restrictions contained in any Content. You will not modify, adapt, translate, reverse engineer, decompile, or disassemble the App or any App Content. You may only use the App and the Content as expressly permitted herein, and any other use is prohibited. Any copying, reproduction, or redistribution of the App or App Content, including by caching, framing, or similar means, is expressly prohibited.</p> -->
          </v-expansion-panel-content>
        </v-expansion-panel>
 
         <v-expansion-panel>
          <v-expansion-panel-header>{{$t("Terms_And_Conditions.Expansion_Panel_8.Heading")}}</v-expansion-panel-header>
          <v-expansion-panel-content style="margin-top:20px;" align="left">
          <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_8.Para_1")}}</p>
          <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_8.Para_2")}}</p>
              <!-- <p> 8.1. You agree that you will be solely responsible for your User Content. You agree, represent and warrant that you have the right to submit the User Content and that all such User Content will comply with <b>The Digital Health Payment Platform</b> Acceptable Use Policy in Clause 11 below. </p>
              <p> 8.2. You agree that you will be liable to <b>The Digital Health Payment Platform</b> and will, to the fullest extent permissible by law, indemnify <b>The Digital Health Payment Platform</b> for any breach of the warranties given by you in these Terms and Conditions. You will be responsible for any loss or damage suffered by <b>The Digital Health Payment Platform</b> from any breach of these Terms and Conditions.   </p>
              <p> 8.3. You retain ownership of your User Content and all intellectual property rights in your User Content. When you submit User Content you grant <b>The Digital Health Payment Platform</b>  an unconditional, fully transferable, royalty-free, worldwide licence to use, store, archive your User Content for the purposes of operating and promoting the App and for providing you with and improving <b>The Digital Health Payment Platform</b> and <b>The Digital Health Payment Platform</b>  Services including the use of that data in anonymized form. We will not share your Health History with a third party other than your Healthcare Provider or if we are required by law to share such information. </p> -->
 
          </v-expansion-panel-content>
        </v-expansion-panel>
 
        <v-expansion-panel>
          <v-expansion-panel-header>{{$t("Terms_And_Conditions.Expansion_Panel_9.Heading")}}</v-expansion-panel-header>
          <v-expansion-panel-content style="margin-top:20px;" align="left">
            <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_9.Para_1")}}</p>
                                     
              <!-- The App may contain advertising and sponsorship, including advertising and sponsorship by <b>The Digital Health Payment Platform.</b>. Advertisers and Sponsors are responsible for ensuring that material submitted for inclusion on the App complies with relevant laws and regulations.<b>The Digital Health Payment Platform</b> will not be responsible for any error or inaccuracy in advertising and sponsorship material. -->
          </v-expansion-panel-content>
        </v-expansion-panel>
 
        <v-expansion-panel>
          <v-expansion-panel-header>{{$t("Terms_And_Conditions.Expansion_Panel_10.Heading")}}</v-expansion-panel-header>
          <v-expansion-panel-content style="margin-top:20px;" align="left">
             <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_10.Para_1")}}</p>
          </v-expansion-panel-content>
        </v-expansion-panel>
 
         <v-expansion-panel>
          <v-expansion-panel-header>{{$t("Terms_And_Conditions.Expansion_Panel_11.Heading")}}</v-expansion-panel-header>
            <v-expansion-panel-content style="margin-top:20px;" align="left">
             <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_11.Para_1")}}</p>
          </v-expansion-panel-content>
        </v-expansion-panel>
 
         <v-expansion-panel>
          <v-expansion-panel-header>{{$t("Terms_And_Conditions.Expansion_Panel_12.Heading")}}</v-expansion-panel-header>
          <v-expansion-panel-content  style="margin-top:20px;" align="left">
             <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_12.Para_1")}}</p> 
 
              <!-- <p>12.1. <b>The Digital Health Payment Platform</b> make all reasonable efforts to ensure that all general descriptions of the Services correspond to the actual Services that will be provided to you, however please note that the exact nature may vary depending upon your individual requirements and circumstances. </p>
              <p>12.2. <b>The Digital Health Payment Platform</b> neither represents nor warrants that all Services will be always available and cannot necessarily confirm availability until your Order is completed.  </p>
              <p>12.3. <b>The Digital Health Payment Platform</b> makes all reasonable efforts to ensure that all prices shown on the App are correct at the time of going online.<b>The Digital Health Payment Platform</b> reserves the right to change prices and to add, alter, or remove prices from time to time as necessary. All pricing information is reviewed and updated regularly.   </p>
              <p>12.4. All prices on the App include commercial tax where applicable. If the commercial tax rate changes between your order being placed and <b>The Digital Health Payment Platform</b> taking payment, the amount of commercial tax payable will be automatically adjusted to the current rate when taking payment. </p> -->
 
          </v-expansion-panel-content>
        </v-expansion-panel>
 
         <v-expansion-panel>
          <v-expansion-panel-header>{{$t("Terms_And_Conditions.Expansion_Panel_13.Heading")}}</v-expansion-panel-header>
            <v-expansion-panel-content style="margin-top:20px;" align="left">
             <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_13.Para_1")}}</p>
          </v-expansion-panel-content>
        </v-expansion-panel>
 
           <v-expansion-panel>
          <v-expansion-panel-header>{{$t("Terms_And_Conditions.Expansion_Panel_14.Heading")}}</v-expansion-panel-header>
            <v-expansion-panel-content style="margin-top:20px;" align="left">
             <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_14.Para_1")}}</p>
          </v-expansion-panel-content>
        </v-expansion-panel>
 
        <v-expansion-panel>
          <v-expansion-panel-header>{{$t("Terms_And_Conditions.Expansion_Panel_15.Heading")}}</v-expansion-panel-header>
          <v-expansion-panel-content style="margin-top:20px;" align="left">
             <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_15.Para_1")}}</p>
             <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_15.Para_2")}}</p>
          </v-expansion-panel-content>
        </v-expansion-panel>
 
        <v-expansion-panel>
        <v-expansion-panel-header>{{$t("Terms_And_Conditions.Expansion_Panel_16.Heading")}}</v-expansion-panel-header>
          <v-expansion-panel-content  style="margin-top:20px;" align="left">
            <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_16.Content")}}</p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
        <v-expansion-panel-header>{{$t("Terms_And_Conditions.Expansion_Panel_17.Heading")}}</v-expansion-panel-header>
          <v-expansion-panel-content  style="margin-top:20px;" align="left">
          <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_17.Content")}}</p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
        <v-expansion-panel-header>{{$t("Terms_And_Conditions.Expansion_Panel_18.Heading")}}</v-expansion-panel-header>
          <v-expansion-panel-content  style="margin-top:20px;" align="left">
          <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_18.Para_1")}}</p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
        <v-expansion-panel-header>{{$t("Terms_And_Conditions.Expansion_Panel_19.Heading")}}</v-expansion-panel-header>
          <v-expansion-panel-content  style="margin-top:20px;" align="left">
            <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_19.Content")}}</p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
        <v-expansion-panel-header>{{$t("Terms_And_Conditions.Expansion_Panel_20.Heading")}}</v-expansion-panel-header>
          <v-expansion-panel-content  style="margin-top:20px;" align="left">
            <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_20.Content")}}</p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
        <v-expansion-panel-header>{{$t("Terms_And_Conditions.Expansion_Panel_21.Heading")}}</v-expansion-panel-header>
          <v-expansion-panel-content  style="margin-top:20px;" align="left">
            <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_21.Para_1")}}
                                       </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
        <v-expansion-panel-header>{{$t("Terms_And_Conditions.Expansion_Panel_22.Heading")}}</v-expansion-panel-header>
          <v-expansion-panel-content  style="margin-top:20px;" align="left">
           <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_22.Para_1")}}
                                       </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
        <v-expansion-panel-header>{{$t("Terms_And_Conditions.Expansion_Panel_23.Heading")}}</v-expansion-panel-header>
          <v-expansion-panel-content  style="margin-top:20px;" align="left">
             <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_23.Para_1")}}
                                       </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
       
        <v-expansion-panel>
        <v-expansion-panel-header>{{$t("Terms_And_Conditions.Expansion_Panel_24.Heading")}}</v-expansion-panel-header>
          <v-expansion-panel-content  style="margin-top:20px;" align="left">
            <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_24.Para_1.Content_1")}}</p>
            <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_24.Para_1.Content_2")}}</p>
            <p style="margin-top:12px">{{$t("Terms_And_Conditions.Expansion_Panel_24.Para_1.Content_3")}}</p>                          
          </v-expansion-panel-content>
        </v-expansion-panel>
         </v-expansion-panels>
       </div>
       </div>
    </v-app>
    </div>
 </template>
 
 <script>
 import NavBar from "../../../../views/navbar.vue";
 import axios from "axios";
 import bottomnavbar from "./../bottomnavbar.vue";
 export default {
   name: "ePharmacyTermsAndConditions",
   data(){
     return{
       isMobile : false,
       show_header: true,
     }
   },
   methods:{
     redirectToHomePage() {
       this.currentCustomer = this.$cookies.get("customerToken");
       if(!this.currentCustomer){
         this.$router.push({
           path : "/signup"
         });
       }
       else{
         this.$router.go(-1);
         // this.$router.push({
         //   name: "UserProfile",
         // });
       }
     }
   },
   mounted(){
     var userAgent = navigator.userAgent
 
   if(this.$store.state.locale !== "")
     {
       this.$i18n.locale =  this.$store.state.locale;
     }
     else
     {
       this.$i18n.locale = "mm";
       this.$store.dispatch("changeLocale", this.$i18n.locale);
     }
 
     if(userAgent.includes("kbzpay")){
       this.show_header = false;
     }
     this.isMobile = this.$device.mobile;
     if(this.$store.state.locale == 'en')
       document.title = "Terms and Conditions"
     else
       document.title = 'စည်းမျဥ်းစည်းကမ်းနှင့် သတ်မှတ်ချက်များ'
 
   }
 }
 </script>
 
 <style >
 /* @media (min-width: 800px){
   .tcMainDiv{
    width: 800px !important;
    margin-left: auto !important ;
     margin-right: auto !important ;
 
   }} */
 </style>
 